<template>
  <div>
    <el-button
      v-for="{ tag_id, tag_name } in dataList"
      :key="tag_id"
      @click="(e) => tagClick(e, tag_name, tag_id)"
      size="mini"
      round
    >
      #{{ tag_name }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "tag-item",
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    tagClick(e, name, id) {
      e.stopPropagation();
      this.$router.push(`/tag/${name}/${id}`);
    },
  },
};
</script>

<style>
</style>