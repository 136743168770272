import { get, post } from '@/api/request';

// 列表
export const cardList = (parmas = { page: 1, limit: 20 }) => get("cardList", parmas, undefined, { showGlobalMessage: false });
// 列表by tagid
export const cardListByTag = (parmas = { page: 1, limit: 20 }) => get("cardListByTag", parmas, undefined, { showGlobalMessage: false });
// 详情
export const cardInfoById = (parmas = { card_id: 1 }) => get("cardInfoById", parmas, undefined, { showGlobalMessage: false });
// 新增
export const cardAdd = (data) => post("cardAdd", data, undefined, { showGlobalMessage: false });
// 更新 暂未启用
export const cardUpdate = (parmas = { card_id: 1, card_content: '内容', card_media: "[]" }) => post("cardUpdate", parmas, undefined, { showGlobalMessage: false });
