<template>
  <a href="javascript:void(0)" @click="autoLike">
    <svg viewBox="0 0 24 24" aria-hidden="true" v-if="!isLike">
      <g>
        <path
          d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
        ></path>
      </g>
    </svg>
    <svg viewBox="0 0 24 24" aria-hidden="true" class="like" v-else>
      <g>
        <path
          d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12z"
        ></path>
      </g>
    </svg>
    <span>{{ count }}</span>
  </a>
</template>

<script>
import { cardLike, cardUnLike } from "@/api/like";
export default {
  name: "like",
  methods: {
    async autoLike(e) {
      e.stopPropagation();

      const requestData = { card_id: this.cardId, comment_id: 0 };
      const { code, msg } =
        this.isLike === 0
          ? await cardLike(requestData)
          : await cardUnLike(requestData);

      //错误提示
      if (code === 200) {
        this.$emit("done", this.sourceKey, this.cardId);
        this.$message.success("操作成功");
      } else {
        this.$message.error(msg);
      }
    },
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    isLike: {
      type: Number,
    },
    cardId: {
      type: Number,
    },
    sourceKey: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: flex;
  text-decoration: none;
  color: rgb(83, 100, 113);

  svg {
    width: 18px;
    height: 18px;
    fill: currentcolor;
    color: rgb(83, 100, 113);

    &.like {
      color: rgb(249, 24, 128);
    }
  }

  span {
    display: block;
    font-size: 13px;
    line-height: 16px;
    padding: 0 10px;
  }
}
</style>