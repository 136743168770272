export default {
    methods: {
        likeComplete(key, card_id) {
            if (Array.isArray(this[key])) {
                this[key].forEach(item => {
                    if (item.card_id === card_id) {
                        if (item.is_like === 1) {
                            item.is_like = 0;
                            --item.card_like;
                        } else {
                            item.is_like = 1;
                            ++item.card_like;
                        }
                    }
                })
            } else {
                if (this[key].is_like === 1) {
                    this[key].is_like = 0;
                    --this[key].card_like;
                } else {
                    this[key].is_like = 1;
                    ++this[key].card_like;
                }
            }
        }
    }
}